import { React, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CarouselComponent from "./CarouselComponent";
import "./Homepage.css";
import "./Layout.css";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Homepage = () => {
  const nav = useNavigate();

  const navToCheckout = () => {
    nav("/bestall");
  };

  return (
    <div className="homepage-container">
      <img
        className="start-image"
        src={require("../media/images/Överraskad.jpg")}
        alt="Nisseprat"
      ></img>
      <div className="start-image-text">
        <img
          className="start-image-text-item nisseprat-logo"
          src={require("../media/images/Nisseprat.png")}
          alt="Nisseprat logga"
        ></img>
        <p className="start-image-text-item">
          Väcker tomtenissen till liv och gör barnens väntan på jul betydligt
          roligare
        </p>
      </div>
      <div className="text-container">
        <h1 className="text-header">Så här säger tidigare kunder</h1>
        <CarouselComponent />
      </div>
      <div className="about-container">
        <div className="text-container">
          <h1 className="text-header">Om Nisseprat</h1>
          <p className="text-item">
            Många hem har numera en liten Nissedörr uppsatt under december
            månad. Med Nisseprat kan du förstärka magin kring Nisses hembesök
            ytterligare. Varje morgon knackar barnen på den lilla dörren.
            Knackningen skapar Nissemagi (detta förklarar Tomtenissen själv i
            det första meddelandet) och en hälsning skickas till en förälders
            telefon/dator/surfplatta. Hälsningen spelas upp och innehåller
            antingen ett uppdrag, lek, bus eller återkoppling på ett tidigare
            meddelande.
          </p>
          <p className="text-item">
            Förutom de 24 ljudhälsningarna från Tomtenissen medföljer även en
            pdf. Där får du överblick över ljudfilernas innehåll och information
            om eventuella förberedelser som krävs av dig som förälder. Alla bus
            och uppdrag är väldigt enkla att genomföra och i pdf-en erbjuds ofta
            olika alternativ så att du själv kan välja ambitionsnivå. Det är
            alltså bara att följa instruktionerna i pdf-en dag för dag, spela
            upp ljudfilerna efter att barnet knacket på dörren och låta
            Nissemagin flöda.
          </p>
          <button className="btn-to-checkout" onClick={navToCheckout}>
            BESTÄLL HÄR
          </button>
        </div>
        <img
          src={require("../media/images/Nissebus.jpg")}
          alt="Nissedörr"
          className="door-image"
        ></img>
      </div>
    </div>
  );
};

export default Homepage;
