import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import { BsInstagram } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import "./Checkoutform.css";

const CARD_OPTIONS = {
  hidePostalCode: true,
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "rgb(208, 233, 245)" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

const CheckoutForm = (props) => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [oneChild, setOneChild] = useState(true);
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const stripe = useStripe();
  const elements = useElements();
  const nav = useNavigate();
  const url = "https://nisseprat.herokuapp.com";
  //const url = "http://localhost:4242";

  const axiosHeader = {
    headers: { "content-type": "application/json", withCredentials: true },
  };

  const navToConfirmed = () => {
    nav("genomford");
  };

  const navToFailed = () => {
    nav("misslyckades");
  };

  const toggleOneChild = () => {
    setOneChild(!oneChild);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    if (email.match(mailformat)) {
      setIsLoading(true);

      try {
        const response = await axios.post(
          url + "/payment",
          {
            amount: props.price * 100,
          },
          axiosHeader
        );
        const data = await response.data;
        const cardElement = elements.getElement(CardElement);
        const confirmPayment = await stripe.confirmCardPayment(
          data.clientSecret,
          {
            payment_method: { card: cardElement },
            receipt_email: email,
          }
        );
        const { paymentIntent } = confirmPayment;
        if (paymentIntent.status === "succeeded") {
          const pdfResponse = await axios.post(
            url + "/sendMail1",
            {
              oneChild: oneChild,
              email: email,
              token: process.env.REACT_APP_MK,
            },
            axiosHeader
          );
          const audioResponse = await axios.post(
            url + "/sendMail2",
            {
              oneChild: oneChild,
              email: email,
              token: process.env.REACT_APP_MK,
            },
            axiosHeader
          );
          navToConfirmed();
        } else {
          setError(true);
          setErrorMessage("Transaktionen avbröts.");
          setIsLoading(false);
        }
      } catch (err) {
        if (err.name == "AxiosError") {
          navToFailed();
        } else {
          setError(true);
          setErrorMessage(
            "Något gick fel... Kontrollera att du angivit korrekta kortuppgifter och att det finns tillräckligt med pengar på kortet."
          );
          setIsLoading(false);
        }
      }
    } else {
      setError(true);
      setErrorMessage("E-postadressen är ogiltig.");
    }
  };

  return (
    <div className="checkout-form-container">
      <form onSubmit={handleSubmit} className="checkout-form">
        <div
          className="text-container"
          style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
        >
          <a className="text-header">Beställning</a>
        </div>
        <div>
          <label className="checkbox-element">
            <input
              type="checkbox"
              className="checkbox"
              checked={oneChild}
              onClick={toggleOneChild}
              style={{ color: "red" }}
              readOnly={true}
            ></input>
            Ett barn
          </label>
          <label className="checkbox-element">
            <input
              type="checkbox"
              className="checkbox"
              checked={!oneChild}
              onClick={toggleOneChild}
              readOnly={true}
            ></input>
            Flera barn
          </label>
        </div>
        {oneChild ? (
          <h2>
            Nisseprat 2023 {"("}ett barn{")"}
          </h2>
        ) : (
          <h2>
            Nisseprat 2023 {"("}flera barn{")"}
          </h2>
        )}
        <h2>{props.price} kr</h2>
        <p style={{ fontWeight: "bold" }}>Det här ingår:</p>
        <p>24 röstmeddelanden från tomtenissen.</p>
        <p>
          Tillhörande PDF som innehåller info om alla bus och uppdrag. Du får
          både ett färgglatt original och en utskriftsvänlig version.
        </p>
        <p>
          Ett bonusklipp från ”Nöd-Nisse”. Ibland kör vardagen ihop sig. Även om
          alla bus och uppdrag är väldigt enkla att genomföra kan det glömmas
          bort eller inte orkas med. Då kan man spela upp det här bonusklippet
          där tomtenissen berättar att han fått höra att det är kaos i
          tomteverkstaden så han måste åka dit en sväng. Thank me later!
        </p>
        <p>
          En grattishälsning från tomtenissen som kan spelas upp för barn som
          fyller år i december.
        </p>
        <div className="info-box">
          <p>
            Det finns även möjlighet att beställa personliga hälsningar från
            Tomtenissen. Denna beställning görs via DM på Instagram{" "}
            {
              <a
                style={{ fontWeight: "bold", fontStyle: "italic" }}
                className="checkout-insta"
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/nisseprat/"
              >
                <BsInstagram size={18}></BsInstagram>
                nisseprat{" "}
              </a>
            }
            eller via mejl{" "}
            {
              <>
                <a style={{ fontWeight: "bold", fontStyle: "italic" }}>
                  <FiMail size={18}></FiMail>
                  kontakt@nisseprat.se
                </a>
                <a>. En personlig hälsning kostar </a>
                <a style={{ fontWeight: "bold" }}>100 kr.</a>
              </>
            }
          </p>
          <p style={{ fontStyle: "italic" }}>
            Alla filer skickas direkt till din E-post
          </p>
          <p>
            <a style={{ fontWeight: "bold" }}>OBS! </a>
            <a>
              Ljudfilerna med tillhörande PDF är endast till för dig och din
              familj. Det är ej tillåtet att vidarebefordra och sprida det till
              andra. Dock får du såklart mer än gärna filma när barnen lyssnar
              och lägga upp på sociala medier. Tagga gärna @nisseprat. Stort
              tack för att du respekterar detta.
            </a>
          </p>
        </div>
        {/*<div className="stripe-message">
          <a>Säker betalning via </a>
          <img
            src={require("../media/images/stripe logo.png")}
            alt={"Stripe"}
            className="stripe-logo"
          ></img>
        </div>
        <div className="stripe-message">
          Stöder Visa, Mastercard och American Express
        </div>
        {error ? <div className="error-message">{errorMessage}</div> : ""}
        <fieldset className="form-group email-group">
          <div className="form-row">
            <input
              className="payment-input"
              value={email}
              placeholder="E-post"
              onChange={handleEmail}
            ></input>
          </div>
        </fieldset>
        <fieldset className="form-group">
          <div className="form-row">
            <CardElement options={CARD_OPTIONS} />
          </div>
        </fieldset>
        {isLoading ? (
          <button
            className="pay-btn"
            style={{ backgroundColor: "rgb(147, 177, 222)" }}
          >
            Transaktionen bearbetas <LoadingSpinner />
          </button>
        ) : (
          <button className="pay-btn">Betala {props.price} kr</button>
        )}*/}
        <p style={{ fontWeight: "bold" }}>
          Håll utkik på Instagram för att inte missa när årets nisse blir redo
          att lämna Nordpolen och flytta in i ert hem.
        </p>
      </form>
    </div>
  );
};

export default CheckoutForm;
