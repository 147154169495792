import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Homepage, { ScrollToTop } from "./components/Homepage";
import Contactpage from "./components/Contactpage";
import StripeContainer from "./components/StripeContainer";
import ExampleBox from "./components/ExampleBox";
import Footer from "./components/Footer";
import PaymentConfirmed from "./components/PaymentConfirmed";
import PaymentFailed from "./components/PaymentFailed";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar active="hem" /> <Homepage /> <Footer />
              </>
            }
          ></Route>
          <Route
            path="/kontakt"
            element={
              <>
                <Navbar active="kontakt" /> <Contactpage /> <Footer />
              </>
            }
          ></Route>
          <Route
            path="/bestall"
            element={
              <>
                <Navbar active="beställ" />
                <StripeContainer />
                <Footer />
              </>
            }
          ></Route>
          <Route
            path="/exempel"
            element={
              <>
                <Navbar active="exempel" />
                <ExampleBox />
                <Footer />
              </>
            }
          ></Route>
          <Route
            path="/bestall/genomford"
            element={
              <>
                <Navbar active="beställ" />
                <PaymentConfirmed />
                <Footer position="fixed" />
              </>
            }
          ></Route>
          <Route
            path="/bestall/misslyckades"
            element={
              <>
                <Navbar active="beställ" />
                <PaymentFailed />
                <Footer position="fixed" />
              </>
            }
          ></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
