import { useNavigate } from "react-router-dom";

const PaymentConfirmed = () => {
  const nav = useNavigate();

  const navToHome = () => {
    nav("/");
  };

  return (
    <div>
      <h2>Ditt köp har genomförts</h2>
      <p style={{ width: "80%", margin: "auto" }}>
        Allt material hittar du alldeles strax i din inkorg i två separata mail.
        Eftersom filerna är ganska stora kan det ta några minuter innan de
        kommer fram och risken finns att mailen hamnar i skräpposten så kolla
        där också. Skulle du mot förmodan fortfarande inte fått båda mailen
        efter tio minuter vänligen kontakta mig så löser vi det. Lycka till!
      </p>
      <button className="back-btn" onClick={navToHome}>
        Tillbaka till startsidan
      </button>
    </div>
  );
};

export default PaymentConfirmed;
