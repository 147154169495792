import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./Checkoutform";

const stripePromise = loadStripe(process.env.REACT_APP_PK);

const StripeContainer = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm price={195} />
    </Elements>
  );
};

export default StripeContainer;
