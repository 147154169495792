import { useNavigate } from "react-router-dom";

const PaymentFailed = () => {
  const nav = useNavigate();

  const navToHome = () => {
    nav("/");
  };

  return (
    <div>
      <h2>Tyvärr gick någonting fel</h2>
      <p style={{ width: "80%", margin: "auto" }}>
        Men var lugn! Följ bara stegen nedan så löser det sig.
      </p>
      <ol style={{ width: "80%", margin: "auto", paddingTop: "15px" }}>
        <li style={{ padding: "10px" }}>
          Prova gärna igen i en annan webbläsare eller med en annan enhet. Ofta
          löser detta problemet. Fungerar det ändå inte?
        </li>
        <li style={{ padding: "10px" }}>
          Mejla kontakt@nisseprat.se så får du filerna skickade till dig
          manuellt och betalning kommer ske via faktura. Den här infon ska
          finnas med i ditt mejl:
        </li>
      </ol>
      <ul style={{ width: "60%", margin: "auto" }}>
        <li>
          Namn och postnummer {"("}så jag kan lägga till dig som kund i
          bokföringsprogrammet{")"}
        </li>
        <li>
          Vilken version du önskar {"("}Syskon/ett barn{")"}
        </li>
      </ul>
      <p>Jag ber verkligen om ursäkt för teknikstrulet!</p>
      <button className="back-btn" onClick={navToHome}>
        Tillbaka till startsidan
      </button>
    </div>
  );
};

export default PaymentFailed;
