import { React, useLayoutEffect, useState } from "react";
import Faq from "react-faq-component";
import { BsInstagram } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import "./Contactpage.css";
import "./Layout.css";

const Contactpage = () => {
  const [screenWidth, setScreenWidth] = useState([window.innerWidth]);
  useLayoutEffect(() => {
    function updateSize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const faqData = {
    title: "Vanliga frågor",
    rows: [
      {
        title:
          "Spelar man bara upp klippen från sin telefon eller hur sker kopplingen till Nissedörren?",
        content:
          "Detta förklarar faktiskt Tomtenissen i sitt första meddelande. Hon berättar varför hon har flyttat hem till barnen och ber dem att knacka på hennes dörr varje dag. På så vis skapas nämligen nissemagi som skickar ett meddelande från henne till barnen via en förälders telefon/dator/surfplatta. <br></br>Tips första december: Be barnen knacka på dörren för att se vad som händer och säg att ni då plötsligt fick något till er telefon och spela sedan upp det första meddelandet (1 dec) där Tomtenissen förklarar ovanstående.",
      },
      {
        title: "Hur mycket tid tar det att förbereda busen?",
        content:
          "Det finns två huvudsyften med Nisseprat. Det första är såklart att skapa magi för barnen och göra december roligare. Det andra syftet är att underlätta för föräldrarna. Tometenissen kan av förklarliga skäl inte busa på egen hand, men alla bus är oerhört enkla att genomföra och tar inte mycket tid. Det är bara att följa pdf-en. I den är det också tydligt markerat vilka dagar som kräver någon typ av förberedelse.",
      },
      {
        title:
          "Behöver man köpa saker för att kunna genomföra Nissens uppdrag?",
        content:
          "Busen utgår från saker man vanligtvis har hemma så grundtanken är att det inte ska behövas. Det är tydligt markerat i pdf-en när förberedelser krävs så dubbelkolla för säkerhets skull! Men min förhoppning med Nisseprat är att det ska krävas minimal förberedelse och jag ger ofta flera olika förslag på hur man kan genomföra uppdragen och komma runt det ifall man inte har föremålet hemma.",
      },
      {
        title: "Vilken ålder passar Nisseprat för?",
        content:
          "Jag skulle säga att Nisseprat lämpar sig bäst för barn mellan 3-7 år, men flera användare har sagt att det här konceptet har fått deras äldre barn att börja tro på tomten igen. Även yngre syskon har haft glädje av busen.",
      },
    ],
  };

  const styles =
    screenWidth > 768
      ? {
          titleTextColor: "rgb(156, 111, 37)",
          rowTitleTextSize: "x-large",
          rowContentTextSize: "large",
        }
      : {
          titleTextColor: "rgb(156, 111, 37)",
          rowTitleTextSize: "large",
          rowContentTextSize: "medium",
        };

  return (
    <>
      <div className="text-container">
        <a className="text-header">Kontakt</a>
        <p className="text-item">
          Om du har frågor som inte besvaras här är det bara att slänga iväg ett
          mail till{" "}
          {
            <a style={{ fontWeight: "bold", fontStyle: "italic" }}>
              <FiMail size={18}></FiMail>
              kontakt@nisseprat.se
            </a>
          }{" "}
          <br></br>eller skicka ett DM på instagram{" "}
          {
            <a
              style={{ fontWeight: "bold", fontStyle: "italic" }}
              className="checkout-insta"
              target="_blank"
              href="https://www.instagram.com/nisseprat/"
            >
              <BsInstagram size={18}></BsInstagram>
              nisseprat.
            </a>
          }
        </p>
      </div>
      <div className="faq-container">
        <Faq data={faqData} styles={styles}></Faq>
      </div>
      <div className="idea-container">
        <div className="images-container">
          <div className="image-row">
            <img
              src={require("../media/images/Uppdrag.jpg")}
              alt="nissebus"
              className="drawing-image"
            ></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactpage;
