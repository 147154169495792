import React from "react";
import "./Footer.css";

const Footer = (props) => {
  return (
    <div
      className="footer"
      style={
        props.position === "fixed"
          ? { position: "fixed", bottom: "0", width: "100%" }
          : {}
      }
    >
      Produceras av Amanda Media AB
    </div>
  );
};

export default Footer;
