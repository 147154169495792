import React from "react";
import "./ExampleBox.css";
import "./Layout.css";
import "./Homepage.css";

const ExampleBox = () => {
  return (
    <>
      <div className="arets-nisse-container">
        <div className="text-container" style={{ paddingBottom: "40px" }}>
          <a className="text-header">Årets tomtenisse</a>
          <p className="text-item arets-nisse">
            Billy Bus är årets tomtenisse! Han heter egentligen Nissesson i
            efternamn, men alla i Tomteland kallar honom för Billy bus, eftersom
            han hittar på så mycket rackartyg. Nu i december har Tomtefar inte
            riktigt tid med Billys påhitt så han föreslår att tomtenissen kan
            flytta hem till några barn och busa där i stället. Så det är precis
            vad han gör! Han tycker om att busa på nätterna när ingen ser, men
            på morgnarna kommer barnen hitta spår av hans tokigheter.
          </p>
        </div>
        <img
          src={require("../media/images/Nissedörr.jpg")}
          alt="Nissedörr"
          className="door-image"
        ></img>
      </div>
      <div className="grey-container">
        <div className="text-container">
          <a className="text-header">Exempel</a>
          <p className="text-item">
            Här kan ni lyssna på ljudfilen som ska spelas upp den 7 december.
            <br></br>
            Ni ser även ett utdrag från den tillhörande PDF-en.
          </p>
        </div>
        <div className="example-container">
          <audio controls className="example-object">
            <source src={require("../media/example.mp3")} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <img
            className="example-object"
            style={{ borderRadius: "20px" }}
            src={require("../media/images/exempel_uppdrag.png")}
            alt="Exempel på nisseuppdrag"
          ></img>
        </div>
      </div>
    </>
  );
};

export default ExampleBox;
