import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./CarouselComponent.css";

const CarouselComponent = () => {
  return (
    <Carousel
      autoPlay={true}
      interval={5000}
      infiniteLoop={true}
      showStatus={false}
      showThumbs={false}
      className="carousel"
    >
      <div className="carousel-text">
        <p>
          ”Så enkelt och lagom mycket att göra för föräldern! Barnen ÄLSKADE
          DET”
        </p>
        <p>- Ebba, Mariannelund</p>
      </div>
      <div className="carousel-text">
        <p>
          ”Barnen älskade det! Så taggade varje morgon på ett nytt meddelande
          från Nissen”
        </p>
        <p>- Jenny, Sollentuna</p>
      </div>
      <div className="carousel-text">
        <p>”Fantastiskt!”</p>
        <p>- Johanna, Kalmar</p>
      </div>
      <div className="carousel-text">
        <p>
          ”Mina barn på 6 och 8 år var misstänksamma när Nissebreven dök upp
          föregående år. Deras tvivel var stora då. Men när Nissen ringde, oj
          vilken succé det blev. Tack”
        </p>
        <p>- Malin, Hultsfred</p>
      </div>
      <div className="carousel-text">
        <p>
          ”Här hemma blev nisseprat en succé. Det blev en magisk stund varje
          morgon när vi fick höra Nisses röst. Enkla bus och uppdrag som är
          hanterligt för en stressad förälder under julstöket. 10 av 10 från
          oss”
        </p>
        <p>- Sandra, Nässjö</p>
      </div>
      <div className="carousel-text">
        <p>
          ”Alltså wow, min dotter på då 2,5 år ÄLSKADE det. Hon pratar
          fortfarande om det och längtar så tills Nisse flyttar in igen. Tvekar
          du? Gör inte det. Så roligt och mysigt både för dig och för barnen”
        </p>
        <p>- Sofia, Göteborg</p>
      </div>
    </Carousel>
  );
};

export default CarouselComponent;
