import React, { useLayoutEffect } from "react";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { CgMenu } from "react-icons/cg";
import { useState } from "react";

const Navbar = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 768) {
        setModalIsOpen(false);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const nav = useNavigate();

  const navToHome = () => {
    nav("/");
  };

  return (
    <>
      <div className="navbar">
        <img
          className="navbar-logo"
          src={require("../media/images/Nisseprat.png")}
          onClick={navToHome}
        ></img>
        <div className="navbar-elements">
          <Link
            className="navbar-element"
            to="/"
            style={
              props.active === "hem" ? { borderBottom: "solid white 3px" } : {}
            }
          >
            Om
          </Link>
          <Link
            className="navbar-element"
            to="/exempel"
            style={
              props.active === "exempel"
                ? { borderBottom: "solid white 3px" }
                : {}
            }
          >
            Exempel
          </Link>
          <Link
            className="navbar-element"
            to="/bestall"
            style={
              props.active === "beställ"
                ? { borderBottom: "solid white 3px" }
                : {}
            }
          >
            Beställ
          </Link>
          <Link
            className="navbar-element"
            to="/kontakt"
            style={
              props.active === "kontakt"
                ? { borderBottom: "solid white 3px" }
                : {}
            }
          >
            Kontakt / FAQ
          </Link>
          <CgMenu
            className="navbar-menu"
            size={30}
            onClick={toggleModal}
            style={
              modalIsOpen
                ? {
                    color: "rgb(156, 111, 37)",
                    border: "solid white 1px",
                    position: "fixed",
                    top: "20px",
                    right: "0px",
                  }
                : {}
            }
          ></CgMenu>
        </div>
      </div>
      <Modal isOpen={modalIsOpen} className="modal" closeTimeoutMS={500}>
        <div className="mobile-navbar-menu">
          <Link
            className="mobile-navbar-menu-item"
            to="/"
            style={
              props.active === "hem" ? { borderBottom: "solid white 3px" } : {}
            }
            onClick={toggleModal}
          >
            Om
          </Link>
          <Link
            className="mobile-navbar-menu-item"
            to="/exempel"
            style={
              props.active === "exempel"
                ? { borderBottom: "solid white 3px" }
                : {}
            }
            onClick={toggleModal}
          >
            Exempel
          </Link>
          <Link
            className="mobile-navbar-menu-item"
            to="/bestall"
            style={
              props.active === "beställ"
                ? { borderBottom: "solid white 3px" }
                : {}
            }
            onClick={toggleModal}
          >
            Beställ
          </Link>
          <Link
            className="mobile-navbar-menu-item"
            to="/kontakt"
            style={
              props.active === "kontakt"
                ? { borderBottom: "solid white 3px" }
                : {}
            }
            onClick={toggleModal}
          >
            Kontakt / FAQ
          </Link>
        </div>
      </Modal>
    </>
  );
};

export default Navbar;
